
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/AccountBoxOutlined'
import ShortUrlField from './Customs/ShortUrlField'
import MultipleField from './Customs/MultipleField'
import MultipleInput from './Customs/MultipleInput'
import DeleteAllButton from './Customs/DeleteAllButton'
import ImportButton from './Customs/ImportButton'
import UpdateInpiButton from './Customs/UpdateInpiButton'
import ApiSelectInput from './Customs/ApiSelectInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const UserIcon = BookIcon

const User_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="login" options={{ maxLength: 1024 }} />
        <TextInput source="email" options={{ maxLength: 1024 }} />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const UserList = (props) => (
    <List {...props} filters={<User_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.login : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="login" sortable={true} />
                    <TextField source="email" sortable={true} />
                    <TextField source="websites_current" sortable={false} />
                    <TextField source="websites_max" sortable={false} />
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)


export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="login" />
            <TextField source="email" />
            <TextField source="websites_current" />
            <TextField source="websites_max" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const UserEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="login" validate={required()} />
            <TextInput source="email" validate={required()} />
            <NumberInput source="websites_max" validate={required()} />
        </SimpleForm>
    </Edit>
)

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="login" validate={required()} />
            <TextInput source="email" validate={required()} />
            <TextInput type='password' source="password" validate={required()} />
        </SimpleForm>
    </Create>
)
